import React from "react";
import Proptypes from "prop-types";
import { Card, CardHeader, CardBody, CardTitle } from "reactstrap";
import { useTranslation } from "react-i18next";
import LogoHeader from "../../commons/LogoHeader";
import { useAuth } from "../../commons/Auth";
import "./Login.scss";

import OAuth2Login from "react-simple-oauth2-login";

import axios from "axios";

function LoginPresentational({ match, onLogin }) {
  const { t } = useTranslation("LOGIN");
  const [, dispatch] = useAuth();
  const onSuccess = ({ code, ...rest }) => {
    axios({
      method: "POST",
      url: `${process.env.REACT_APP_BASE_URL}authorize/token`,
      data: { code, type: "internal" },
    })
      .then((data) => {
        console.log(data);
        dispatch({ type: "login", token: data.data.access_token });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const onFailure = (response) => {
    console.log(response, process.env);
    // onLogin({ email: "", password: "" });
  };

  return (
    <div className="App">
      <LogoHeader match={match} />

      <section>
        <div className="container">
          <Card className="u-center-horizontal login-card">
            <CardHeader>
              <CardTitle>
                <span>{t("DO_LOGIN")}</span>
              </CardTitle>
            </CardHeader>
            <CardBody>
              <OAuth2Login
                className="u-center-horizontal primary-button"
                buttonText="Login with EH"
                authorizationUrl={process.env.REACT_APP_AUTHORIZATION_URL}
                responseType="code"
                clientId={process.env.REACT_APP_CLIENT_ID}
                scope="openid"
                redirectUri={process.env.REACT_APP_REDIRECT_URI}
                onSuccess={onSuccess}
                onFailure={onFailure}
              />
            </CardBody>
          </Card>
        </div>
      </section>
    </div>
  );
}

LoginPresentational.propTypes = {
  onLogin: Proptypes.func.isRequired,
};

export default LoginPresentational;
