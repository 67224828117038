import React from "react";
import { useAlert } from "react-alert";
import { useTranslation } from "react-i18next";
import login from "../../lib/fetch/login";
import { formatError } from "./formatError";
import { useAuth } from "../../commons/Auth";
import LoginPresentational from "./LoginPresentational";

function Login(props) {
  const { t } = useTranslation("LOGIN");
  const alert = useAlert();
  const [, dispatch] = useAuth();

  const onLogin = async ({ email = "", password = "" }) => {
    const { error, data = {} } = await login(email, password);
    if (error) {
      dispatch({ type: "logout" });
      alert.error(formatError(error, t));
      return;
    }

    const { token } = data;
    dispatch({ type: "login", token });
    // eslint-disable-next-line no-restricted-globals
    scrollTo(0, 0);
  };

  return <LoginPresentational onLogin={onLogin} {...props} />;
}

export default Login;
