import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../commons/Auth';
import './Header.scss';

function Header({ title, icon }) {
  const [t] = useTranslation();
  const [auth = {}, dispatch] = useAuth();

  const { profile = {} } = auth;
  const { first_name = '', last_name = '' } = profile;

  const logout = () => dispatch({ type: 'logout' });

  return (
    <div className="header-crm">
      <div className="row">
        <div className="col-sm-12 col-md-12 col-lg-4">
          <h2>
            {icon}
            {title}
          </h2>
        </div>
        <div className="col-sm-12 col-md-12 col-lg-8">
          <div className="cont-header-element">
            <button onClick={() => logout()} className="btn btn-logout">
              Logout
            </button>
            {first_name && last_name && (
              <p>
                {t('COMMON:HELLO')},{' '}
                <span>
                  {first_name} {last_name}
                </span>
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
