import React, { useState, useEffect } from "react";
import { useAlert } from "react-alert";
import { useAuth } from "../../commons/Auth";
import TablePresentational from "./TablePresentational";
import axios from "axios";
import "./Table.scss";
import { useLoading } from "../../commons/Loading";
import { useTranslation } from "react-i18next";
const FileDownload = require("js-file-download");

function Deals(props) {
  const [t] = useTranslation();
  const [isLoading] = useState(false);
  const [data, setData] = useState([]);
  const [auth = {}] = useAuth();
  const { token } = auth;
  const { showLoading, hideLoading } = useLoading();

  const alert = useAlert();

  const fetchdata = () => {
    fetch(`${process.env.REACT_APP_API_AGENTS}`, {
      headers: { Authorization: `${token}` },
    })
      .then((res) => res.json())
      .then((res) => setData(res))
      .catch(() => alert.error("ERROR FETCHING AGENTS"));
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(fetchdata, []);

  const handleUpload = async (e, id) => {
    if (e.target.files.length > 0) {
      var formData = new FormData();
      formData.append("unsignedDocument", e.target.files[0]);
      formData.append("id", id);
      showLoading();
      try {
        const { status } = await axios({
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
          data: formData,
          url: process.env.REACT_APP_API_DOCS + "/upload",
        });
        if (status) {
          alert.success(t("DOCUMENTO CARICATO"));
        } else {
          alert.error(t("ERRORE DURANTE IL CARICAMENTO"));
        }
        fetchdata();
        hideLoading();
      } catch (error) {
        alert.error(t("ERRORE DURANTE IL CARICAMENTO"));
        hideLoading();
      }
    }
  };

  const downloadDocument = async (filename) => {
    showLoading();
    try {
      const { status, data } = await axios({
        method: "GET",
        url: `${process.env.REACT_APP_API_DOCS}/download/${filename}`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log(status, data);
      if (status && status === 200) {
        FileDownload(data.file, data.metadata.fileName);
        alert.success("DOCUMENTO SCARICATO");
      } else alert.error("ERRORE DURANTE IL DOWNLOAD");
      hideLoading();
    } catch (error) {
      alert.error("ERRORE DURANTE IL DOWNLOAD");
      hideLoading();
    }
  };

  const refuseDocument = async (agentId, filename) => {
    try {
      const { status } = await axios({
        method: "PUT",
        url: `${process.env.REACT_APP_API_DOCS}/refuse`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: {
          agentId,
          filename,
        },
      });
      if (status) {
        alert.success("DOCUMENTO RIFIUTATO");
      } else {
        alert.error("ERRORE DURANTE L'AGGIORNAMENTO");
      }

      fetchdata();
    } catch (error) {
      alert.error("ERRORE DURANTE L'AGGIORNAMENTO");
    }
  };

  return (
    <TablePresentational
      data={data}
      isLoading={isLoading}
      fetchdata={fetchdata}
      handleUpload={handleUpload}
      downloadDocument={downloadDocument}
      refuseDocument={refuseDocument}
      {...props}
    />
  );
}

export default Deals;
