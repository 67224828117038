import React, { useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import { useAlert } from "react-alert";
import { useTranslation } from "react-i18next";
import axios from "axios";
import moment from "moment";
import "./ModalBlock.scss";
import "react-datepicker/dist/react-datepicker.css";
import { useLoading } from "../../commons/Loading";
import ModalTable from "../ModalTable";
import { useAuth } from "../../commons/Auth";

function ModalBlock({
  data = {},
  fetchdata,
  downloadDocument,
  refuseDocument,
}) {
  const { t } = useTranslation("AGENTS");
  const alert = useAlert();
  const [modal, setModal] = useState();
  const [agent, setAgent] = useState(data);
  const [auth = {}] = useAuth();
  const { token } = auth;
  const { showLoading, hideLoading } = useLoading();

  const {
    id = undefined,
    type = "",
    email = "",
    iban = "",
    companyRui = "",
    agentFiscalCode = "",
    company = "",
    companyCity = "",
    agentName = "",
    agentSurname = "",
    agentRui = "",
    agentBirthday = undefined,
    agentCity = "",
    policyName = "",
    policyNumber = "",
    policyExpiration = undefined,
    policyCeiling = "",
    externalId = "",
  } = agent;

  const toggle = () => {
    setModal(!modal);
  };

  const updateAgent = async () => {
    showLoading();
    try {
      const info = { ...agent };
      const { status } = await axios({
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: info,
        url: process.env.REACT_APP_API_AGENTS,
      });
      setModal(!modal);
      fetchdata();
      if (status) {
        alert.success(t("UTENTE AGGIORNATO!"));
      } else {
        alert.error(t("ERRORE DURANTE L'AGGIORNAMENTO"));
      }
      hideLoading();
    } catch (error) {
      alert.error(t("ERRORE DURANTE L'AGGIORNAMENTO"));
      hideLoading();
    }
  };

  const solicitateUser = async () => {
    showLoading();
    try {
      const { status } = await axios({
        method: "POST",
        url: `${process.env.REACT_APP_API_AGENTS}/solicitate/${id}`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (status) {
        alert.success(t("SOLICITATE_OK"));
      } else {
        alert.error(t("SOLICITATE_ERROR"));
      }

      hideLoading();
    } catch (error) {
      alert.error(t("SOLICITATE_ERROR"));
      hideLoading();
    }
  };

  return (
    <div>
      <button className="primary-button" onClick={toggle}>
        Visualizza Utente
      </button>
      <Modal isOpen={modal} fade={false} toggle={toggle}>
        <ModalHeader toggle={toggle}>Dettaglio Utente</ModalHeader>
        <ModalBody>
          <p>
            <b>Informazioni:</b> {type}
          </p>
          <Form className="m-b-35">
            <FormGroup>
              <Label for="email">Email</Label>
              <Input
                type="text"
                name="email"
                id="email"
                value={email}
                onChange={(event) =>
                  setAgent({ ...agent, email: event.target.value })
                }
              />
            </FormGroup>
            <FormGroup>
              <Label for="società">Società</Label>
              <Input
                type="text"
                name="società"
                id="società"
                value={company}
                onChange={(event) =>
                  setAgent({ ...agent, company: event.target.value })
                }
              />
            </FormGroup>
            <FormGroup>
              <Label for="sede-legale">Sede Legale</Label>
              <Input
                type="text"
                name="sede-legale"
                id="sede-legale"
                value={companyCity}
                onChange={(event) =>
                  setAgent({ ...agent, companyCity: event.target.value })
                }
              />
            </FormGroup>
            <FormGroup>
              <Label for="rui">Rui</Label>
              <Input
                type="text"
                name="rui"
                id="rui"
                value={companyRui || ""}
                onChange={(event) =>
                  setAgent({ ...agent, companyRui: event.target.value })
                }
              />
            </FormGroup>
            <FormGroup>
              <Label for="nome-rappresentante">Nome Rappresentante</Label>
              <Input
                type="text"
                name="nome-rappresentante"
                id="nome-rappresentante"
                value={agentName || ""}
                onChange={(event) =>
                  setAgent({ ...agent, agentName: event.target.value })
                }
              />
            </FormGroup>
            <FormGroup>
              <Label for="cognome-rappresentante">Cognome Rappresentante</Label>
              <Input
                type="text"
                name="cognome-rappresentante"
                id="cognome-rappresentante"
                value={agentSurname || ""}
                onChange={(event) =>
                  setAgent({
                    ...agent,
                    agentSurname: event.target.value,
                  })
                }
              />
            </FormGroup>
            <FormGroup>
              <Label for="rui-rappresentante">Rui Rappresentante</Label>
              <Input
                type="text"
                name="rui-rappresentante"
                id="rui-rappresentante"
                value={agentRui || ""}
                onChange={(event) =>
                  setAgent({ ...agent, agentRui: event.target.value })
                }
              />
            </FormGroup>
            <FormGroup>
              <Label for="data-rappresentante">
                Data di nascita Rappresentante
              </Label>
              <Input
                type="date"
                name="data-rappresentante"
                id="data-rappresentante"
                value={moment(agentBirthday).format("yyyy-MM-DD")}
                onChange={(event) =>
                  setAgent({ ...agent, agentBirthday: event.target.value })
                }
              />
            </FormGroup>
            <FormGroup>
              <Label for="luogo-rappresentante">
                Luogo di nascita Rappresentante
              </Label>
              <Input
                type="text"
                name="luogo-rappresentante"
                id="luogo-rappresentante"
                value={agentCity || ""}
                onChange={(event) =>
                  setAgent({
                    ...agent,
                    agentCity: event.target.value,
                  })
                }
              />
            </FormGroup>
            <FormGroup>
              <Label for="iban">IBAN</Label>
              <Input
                type="text"
                name="iban"
                id="iban"
                value={iban || ""}
                onChange={(event) =>
                  setAgent({
                    ...agent,
                    iban: event.target.value,
                  })
                }
              />
            </FormGroup>
            <FormGroup>
              <Label for="nome-polizza">Nome Polizza RC</Label>
              <Input
                type="text"
                name="nome-polizza"
                id="nome-polizza"
                value={policyName || ""}
                onChange={(event) =>
                  setAgent({ ...agent, policyName: event.target.value })
                }
              />
            </FormGroup>
            <FormGroup>
              <Label for="numero-polizza">N° Polizza RC</Label>
              <Input
                type="text"
                name="numero-polizza"
                id="numero-polizza"
                value={policyNumber || ""}
                onChange={(event) =>
                  setAgent({ ...agent, policyNumber: event.target.value })
                }
              />
            </FormGroup>
            <FormGroup>
              <Label for="scadenza-polizza">Scadenza RC</Label>
              <Input
                type="date"
                name="scadenza-polizza"
                id="scadenza-polizza"
                value={moment(policyExpiration).format("yyy-MM-DD")}
                onChange={(event) =>
                  setAgent({ ...agent, policyExpiration: event.target.value })
                }
              />
            </FormGroup>
            <FormGroup>
              <Label for="massimale-polizza">Massimale RC</Label>
              <Input
                type="text"
                name="massimale-polizza"
                id="massimale-polizza"
                value={policyCeiling || ""}
                onChange={(event) =>
                  setAgent({ ...agent, policyCeiling: event.target.value })
                }
              />
            </FormGroup>
            <FormGroup>
              <Label for="p-iva">P. IVA</Label>
              <Input
                type="text"
                name="p-iva"
                id="p-iva"
                value={agentFiscalCode || ""}
                onChange={(event) =>
                  setAgent({ ...agent, agentFiscalCode: event.target.value })
                }
              />
            </FormGroup>
            <FormGroup>
              <Label for="p-iva">Codice EH (EHN)</Label>
              <Input
                type="text"
                name="external-id"
                id="external-id"
                value={externalId || ""}
                onChange={(event) =>
                  setAgent({ ...agent, externalId: event.target.value })
                }
              />
            </FormGroup>
          </Form>
          <p>
            <b>Iscritto:</b>{" "}
          </p>
          <p>
            <b>Documenti</b>{" "}
          </p>
          <ModalTable
            data={data}
            downloadDocument={downloadDocument}
            refuseDocument={refuseDocument}
          />
        </ModalBody>
        <ModalFooter>
          {/* <button className="primary-button" onClick={toggle}>
						Crea utente
					</button> */}
          <button className="primary-button" onClick={solicitateUser}>
            Sollecita Documenti
          </button>
          <button className="primary-button" onClick={updateAgent}>
            Modifica Dati
          </button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default ModalBlock;
